const DEPRECATED_ATTRS = {
  htmlmasked: 'hidden',
  masked: 'obscured'
};
export const IN_BROWSER = !(typeof window === 'undefined');
export const IS_FIREFOX = IN_BROWSER && navigator.userAgent.match(/firefox|fxios/i);
export const MAX_STR_LEN = 1e5;
// Buggy to use `performance.timeOrigin || performance.timing.navigationStart`
// https://github.com/mdn/content/issues/4713
// Maybe move to timer/ticker
let timeOrigin = IN_BROWSER ? Date.now() - performance.now() : 0;
export function adjustTimeOrigin() {
  timeOrigin = Date.now() - performance.now();
}
export function getTimeOrigin() {
  return timeOrigin;
}
export const now = IN_BROWSER && !!performance.now ? () => Math.round(performance.now() + timeOrigin) : () => Date.now();
export const stars = 'repeat' in String.prototype ? str => '*'.repeat(str.length) : str => str.replace(/./g, '*');
export function normSpaces(str) {
  return str.trim().replace(/\s+/g, ' ');
}
// isAbsoluteUrl regexp:  /^([a-z][a-z\d\+\-\.]*:)?\/\//i.test(url)
export function isURL(s) {
  return s.startsWith('https://') || s.startsWith('http://');
}
// TODO: JOIN IT WITH LOGGER somehow (use logging decorators?); Don't forget about index.js loggin when there is no logger instance.
export const DOCS_HOST = 'https://docs.openreplay.com';
const warnedFeatures = {};
export function deprecationWarn(nameOfFeature, useInstead, docsPath = '/') {
  if (warnedFeatures[nameOfFeature]) {
    return;
  }
  console.warn(`OpenReplay: ${nameOfFeature} is deprecated. ${useInstead ? `Please, use ${useInstead} instead.` : ''} Visit ${DOCS_HOST}${docsPath} for more information.`);
  warnedFeatures[nameOfFeature] = true;
}
export function getLabelAttribute(e) {
  let value = e.getAttribute('data-openreplay-label');
  if (value !== null) {
    return value;
  }
  value = e.getAttribute('data-asayer-label');
  if (value !== null) {
    deprecationWarn('"data-asayer-label" attribute', '"data-openreplay-label" attribute', '/');
  }
  return value;
}
export function hasOpenreplayAttribute(e, attr) {
  const newName = `data-openreplay-${attr}`;
  if (e.hasAttribute(newName)) {
    // @ts-ignore
    if (DEPRECATED_ATTRS[attr]) {
      deprecationWarn(`"${newName}" attribute`,
      // @ts-ignore
      `"${DEPRECATED_ATTRS[attr]}" attribute`, '/installation/sanitize-data');
    }
    return true;
  }
  return false;
}
/**
 * checks if iframe is accessible
 **/
export function canAccessIframe(iframe) {
  try {
    return Boolean(iframe.contentDocument);
  } catch (e) {
    return false;
  }
}
function dec2hex(dec) {
  return dec.toString(16).padStart(2, '0');
}
export function generateRandomId(len) {
  const arr = new Uint8Array((len || 40) / 2);
  // msCrypto = IE11
  // @ts-ignore
  const safeCrypto = window.crypto || window.msCrypto;
  if (safeCrypto) {
    safeCrypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
  } else {
    return Array.from({
      length: len || 40
    }, () => dec2hex(Math.floor(Math.random() * 16))).join('');
  }
}
export function inIframe() {
  try {
    return window.self && window.top && window.self !== window.top;
  } catch (e) {
    return true;
  }
}
/**
 * Because angular devs decided that its a good idea to override a browser apis
 * we need to use this to achieve safe behavior
 * */
export function ngSafeBrowserMethod(method) {
  // @ts-ignore
  return window.Zone && '__symbol__' in window.Zone ?
  // @ts-ignore
  window['Zone']['__symbol__'](method) : method;
}
export function createMutationObserver(cb) {
  const mObserver = ngSafeBrowserMethod('MutationObserver');
  return new window[mObserver](cb);
}
export function createEventListener(target, event, cb, capture) {
  const safeAddEventListener = ngSafeBrowserMethod('addEventListener');
  try {
    target[safeAddEventListener](event, cb, capture);
  } catch (e) {
    const msg = e.message;
    console.debug(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `Openreplay: ${msg}; if this error is caused by an IframeObserver, ignore it`);
  }
}
export function deleteEventListener(target, event, cb, capture) {
  const safeRemoveEventListener = ngSafeBrowserMethod('removeEventListener');
  try {
    target[safeRemoveEventListener](event, cb, capture);
  } catch (e) {
    const msg = e.message;
    console.debug(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `Openreplay: ${msg}; if this error is caused by an IframeObserver, ignore it`);
  }
}
class FIFOTaskScheduler {
  constructor() {
    this.taskQueue = [];
    this.isRunning = false;
  }
  // Adds a task to the queue
  addTask(task) {
    this.taskQueue.push(task);
    this.runTasks();
  }
  // Runs tasks from the queue
  runTasks() {
    if (this.isRunning || this.taskQueue.length === 0) {
      return;
    }
    this.isRunning = true;
    const executeNextTask = () => {
      if (this.taskQueue.length === 0) {
        this.isRunning = false;
        return;
      }
      // Get the next task and execute it
      const nextTask = this.taskQueue.shift();
      Promise.resolve(nextTask()).then(() => {
        requestAnimationFrame(() => executeNextTask());
      });
    };
    executeNextTask();
  }
}
const scheduler = new FIFOTaskScheduler();
export function requestIdleCb(callback) {
  // performance improvement experiment;
  scheduler.addTask(callback);
  /**
   * This is a brief polyfill that suits our needs
   * I took inspiration from Microsoft Clarity polyfill on this one
   * then adapted it a little bit
   *
   * I'm very grateful for their bright idea
   * */
  // const taskTimeout = 3000
  // if (window.requestIdleCallback) {
  //   return window.requestIdleCallback(callback, { timeout: taskTimeout })
  // } else {
  //   const channel = new MessageChannel()
  //   const incoming = channel.port1
  //   const outgoing = channel.port2
  //
  //   incoming.onmessage = (): void => {
  //     callback()
  //   }
  //   requestAnimationFrame((): void => {
  //     outgoing.postMessage(1)
  //   })
  // }
}
export function simpleMerge(defaultObj, givenObj) {
  const result = {
    ...defaultObj
  };
  for (const key in givenObj) {
    // eslint-disable-next-line no-prototype-builtins
    if (givenObj.hasOwnProperty(key)) {
      const userOptionValue = givenObj[key];
      const defaultOptionValue = defaultObj[key];
      if (typeof userOptionValue === 'object' && !Array.isArray(userOptionValue) && userOptionValue !== null) {
        result[key] = simpleMerge(defaultOptionValue || {}, userOptionValue);
      } else {
        result[key] = userOptionValue;
      }
    }
  }
  return result;
}