export const LogLevel = {
  Verbose: 5,
  Log: 4,
  Warnings: 3,
  Errors: 2,
  Silent: 0
};
export default class Logger {
  constructor(debugLevel = LogLevel.Silent) {
    this.shouldLog = level => {
      return this.level >= level;
    };
    this.log = (...args) => {
      if (this.shouldLog(LogLevel.Log)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        console.log(...args);
      }
    };
    this.warn = (...args) => {
      if (this.shouldLog(LogLevel.Warnings)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        console.warn(...args);
      }
    };
    this.error = (...args) => {
      if (this.shouldLog(LogLevel.Errors)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        console.error(...args);
      }
    };
    this.level = debugLevel;
  }
}